import { TToken } from '../defs/const';

export type TJWTToken = { raw: string, header: {}, payload: TToken };

export default function jwtDecode(t: string) {
    // @ts-ignore
    let token: TJWTToken = { raw: '', header: {}, payload: {} };
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token);
}

function parseJwt(token: string): any {
    if (!token) {
      return;
    }

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
}
