import React from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import dayjs from 'dayjs';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Const from "../defs/const";
import { ISerializedUser } from "../defs/SocketDefs";

export type TNewUserDialogProps = {
    open: boolean;
    user?: ISerializedUser;
    onClose: () => void;
    onUserCreated: (client: ISerializedUser) => boolean;
}

export const EmptyUser: ISerializedUser = {
    _id: '',
    username: '',
    password: '',
    name: '',
    role: Const.ROLES.SIRC, 
    department: '',
    region: '',
    status: Const.USER_STATUS.PENDING,
    createdAt: new Date(),
    updatedAt: new Date(),
    completedAt: undefined,
    notes: ''
};

export const NewUserDialog: React.FC<TNewUserDialogProps> = (props) => {
    const [user, setUser] = React.useState<ISerializedUser>(Object.assign({}, props.user || EmptyUser));

    const handleUserChange = (key: keyof ISerializedUser) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [key]: event.target.value });
    }

    const handleDateChange = (key: keyof ISerializedUser, newDate: Date | undefined) => {
        if (newDate) setUser({ ...user, [key]: newDate });
    }

    const handleSelectChange = (key: keyof ISerializedUser) => (event: SelectChangeEvent) => {
        setUser({ ...user, [key]: event.target.value });
    }

    const handleCreateUser = () => {
        if (props.onUserCreated(user)) setUser(Object.assign({}, EmptyUser));
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{user._id ? `Edit a user` : `Create New User`}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Username"
                    value={user.username}
                    onChange={handleUserChange("username")}
                    fullWidth
                    sx={{
                        marginBottom: 2,
                        marginTop: 2
                     }}
                />
                <TextField
                    label="Password"
                    value={user.password}
                    onChange={handleUserChange("password")}
                    fullWidth
                    sx={{
                        marginBottom: 2,
                        marginTop: 2
                     }}
                />
                <TextField
                    label="Name"
                    value={user.name}
                    onChange={handleUserChange("name")}
                    fullWidth
                    sx={{
                        marginBottom: 2,
                        marginTop: 2
                     }}
                />
                <Select
                    label="Role"
                    fullWidth
                    value={user.role}
                    onChange={handleSelectChange("role")}
                    sx={{ marginBottom: 2 }}
                >
                    {Object.keys(Const.ROLES).map((role) => (
                        <MenuItem key={role} value={role}>{role}</MenuItem>
                    ))}
                </Select>
                <TextField
                    label="Department"
                    value={user.department}
                    onChange={handleUserChange("department")}
                    fullWidth
                    sx={{
                        marginBottom: 2,
                        marginTop: 2
                     }}
                />
                <TextField
                    label="Region"
                    value={user.region}
                    onChange={handleUserChange("region")}
                    fullWidth
                    sx={{
                        marginBottom: 2,
                        marginTop: 2
                     }}
                />
                <Select
                    label="Status"
                    fullWidth
                    value={user.status}
                    onChange={handleSelectChange("status")}
                    sx={{ marginBottom: 2 }}
                >
                    <MenuItem value={Const.USER_STATUS.PENDING}>Pending</MenuItem>
                    <MenuItem value={Const.USER_STATUS.ACTIVE}>Active</MenuItem>
                    <MenuItem value={Const.USER_STATUS.DELETED}>Deleted</MenuItem>
                </Select>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker', 'DateTimePicker', 'DateTimePicker']} sx={{ marginBottom: 2 }} >
                        <DateTimePicker label="Created At" value={dayjs(user.createdAt)} onChange={(e) => handleDateChange("createdAt", e?.toDate())} />
                        <DateTimePicker label="Updated At" value={dayjs(user.updatedAt)} onChange={(e) => handleDateChange("updatedAt", e?.toDate())} />
                        <DateTimePicker label="Completed At" value={dayjs(user.completedAt)} onChange={(e) => handleDateChange("completedAt", e?.toDate())} />
                    </DemoContainer>
                </LocalizationProvider>
                <TextField
                    label="Notes"
                    multiline
                    rows={2}
                    value={user.notes}
                    onChange={handleUserChange("notes")}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={handleCreateUser}>{user._id ? `Save` : `Create`}</Button>
            </DialogActions>
        </Dialog>
    );
}
