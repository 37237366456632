import React from 'react';
import './App.css';
import Const from '../defs/const';
import { Button, Grid, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import queryString from 'query-string';

const Login = () => {
  const queryToken = queryString.parse(window.location.search).token;
  // console.log(`queryToken is ${queryToken}`);

  if (queryToken) {
    window.localStorage.setItem(Const.LOCAL_STORAGE.TOKEN, queryToken.toString());
    console.log(`about to redirect to ${window.location.origin + window.location.pathname}`)
    window.location.replace(window.location.origin + window.location.pathname);
  }

  // const loadedJWT = window.localStorage.getItem(Const.LOCAL_STORAGE.TOKEN);
  const appendix = window.location.pathname.endsWith('azure') ? "" : window.location.pathname.endsWith('/') ? 'azure' : '/azure';
  const params = new URLSearchParams(window.location.search);
  const redirect_to = params.get("redirect_to");

  return (
    <Grid container spacing={3} direction="column" alignItems="center" sx={{
      display: 'flex',
      flexGrow: 1,
      minHeight: '99vh'
    }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Restricted realm
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Available authentication methods
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" startIcon={<LockIcon />} size="large" onClick={() => { window.location.replace(`${window.location.origin + window.location.pathname}${appendix}` + (redirect_to ? `?redirect_to=${encodeURIComponent(redirect_to)}` : '')) }}>
          Azure
        </Button>
      </Grid>
    </Grid>
  );
};

// `?redirect_to=${window.location.origin + window.location.pathname}`
export default Login;