import React from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import dayjs, { Dayjs } from 'dayjs';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Const, { EnumClientType, TClientStatus } from "../defs/const";
import { ISerializedClient } from "../defs/SocketDefs";

export type TNewClientDialogProps = {
    open: boolean;
    client?: ISerializedClient;
    onClose: () => void;
    onClientCreated: (client: ISerializedClient) => boolean;
}

export const EmptyClient: ISerializedClient = {
    _id: '',
    name: "",
    clientType: EnumClientType.ADHOC,
    usedHours: 0,
    totalHours: 0,
    hoursResetAt: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
    createdAt: new Date(),
    status: Const.CLIENT_STATUS.ACTIVE,
    notes: ""
};

export const NewClientDialog: React.FC<TNewClientDialogProps> = (props) => {
    const [client, setClient] = React.useState<ISerializedClient>(Object.assign({}, props.client || EmptyClient));

    const handleClientChange = (key: keyof ISerializedClient) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setClient({ ...client, [key]: event.target.value });
    }

    const handleDateChange = (key: keyof ISerializedClient, newDate: Date | undefined) => {
        if (newDate) setClient({ ...client, [key]: newDate });
    }

    const handleSelectChange = (key: keyof ISerializedClient) => (event: SelectChangeEvent) => {
        setClient({ ...client, [key]: event.target.value });
    }

    const handleCreateClient = () => {
        if (props.onClientCreated(client)) setClient(Object.assign({}, EmptyClient));
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{client._id ? `Edit a client` : `Create New Client`}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    value={client.name}
                    onChange={handleClientChange("name")}
                    fullWidth
                    sx={{
                        marginBottom: 2,
                        marginTop: 2
                     }}
                />
                <Select
                    label="Type"
                    fullWidth
                    value={client.clientType}
                    onChange={handleSelectChange("clientType")}
                    sx={{ marginBottom: 2 }}
                >
                    <MenuItem value={EnumClientType.ADHOC}>Ad Hoc</MenuItem>
                    <MenuItem value={EnumClientType.RETAINER}>Retainer</MenuItem>
                </Select>
                <TextField
                    label="Used Hours"
                    value={client.usedHours}
                    type="number"
                    onChange={handleClientChange("usedHours")}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Total Hours"
                    value={client.totalHours}
                    type="number"
                    onChange={handleClientChange("totalHours")}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker', 'DateTimePicker', 'DateTimePicker']} sx={{ marginBottom: 2 }} >
                        <DateTimePicker label="Hours Reset At" value={dayjs(client.hoursResetAt)} onChange={(e) => handleDateChange("hoursResetAt", e?.toDate())} />
                        <DateTimePicker label="Created At" value={dayjs(client.createdAt)} onChange={(e) => handleDateChange("createdAt", e?.toDate())} />
                        <DateTimePicker label="Deleted At" value={dayjs(client.deletedAt)} onChange={(e) => handleDateChange("deletedAt", e?.toDate())} />
                    </DemoContainer>
                </LocalizationProvider>
                <Select
                    label="Status"
                    fullWidth
                    value={client.status}
                    onChange={handleSelectChange("status")}
                    sx={{ marginBottom: 2 }}
                >
                    <MenuItem value={Const.CLIENT_STATUS.PENDING}>Pending</MenuItem>
                    <MenuItem value={Const.CLIENT_STATUS.ACTIVE}>Active</MenuItem>
                    <MenuItem value={Const.CLIENT_STATUS.REJECTED}>Rejected</MenuItem>
                    <MenuItem value={Const.CLIENT_STATUS.DISABLED}>Disabled</MenuItem>
                    <MenuItem value={Const.CLIENT_STATUS.DELETED}>Deleted</MenuItem>
                </Select>
                <TextField
                    label="Notes"
                    multiline
                    rows={2}
                    value={client.notes}
                    onChange={handleClientChange("notes")}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={handleCreateClient}>{client._id ? `Save` : `Create`}</Button>
            </DialogActions>
        </Dialog>
    );
}
