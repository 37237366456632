import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Provider from './modules/Reducer/ReducerContext';
import MySocket from './modules/MySocket';
import Const from './defs/const';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// if the hostname is 'localhost' then connect the socket to
// the dev backend on the server
// otherwise just use the same port/url
let sockUrl = window.location.hostname;

if (window.location.hostname === 'localhost') {
  sockUrl = `http://localhost:10002`;
} else {
  let myPort: number = -1;

  if (window.location.port && window.location.port.length > 0) {
    myPort = parseInt(window.location.port);
    if (isNaN(myPort)) myPort = 10002;
  }

  if (myPort > 0) sockUrl = `${window.location.protocol}//${window.location.hostname}:${myPort}`;
  else sockUrl = `${window.location.protocol}//${window.location.hostname}`;  
}

console.log(`MySocket must connect to`, sockUrl);

root.render(
  <React.StrictMode>
    <Provider>
      <MySocket uri={sockUrl} path={Const.SOCKETIO_PATH} autoConnect={true}>
        <App />
      </MySocket>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
