import React from "react";
import { useMemo } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

import Const from '../defs/const';
import { ISerializedCase } from '../defs/SocketDefs';
import { ReducerContext } from '../modules/Reducer/ReducerContext';
import { Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { NewCaseDialog } from "../components/NewCaseDialog";

const Cases = () => {
  const reducerContext = React.useContext(ReducerContext);
  const [open, setOpen] = React.useState(false);
  const [selectedCase, setSelectedCase] = React.useState<ISerializedCase | undefined>(undefined);
  
  const handleOpen = () => {
    if (reducerContext.state.casenames.length <= 0) {
      console.log('Requesting case names...');
      reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.CASE_NAMES_GET, { count: 5 });
    }

    if (reducerContext.state.sftpHosts.length <= 0) {
      console.log('Requesting SFTP Hosts...');
      reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.SFTP_HOSTS_GET, {});
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCaseCreated = (caze: ISerializedCase, sftpHost?: string): boolean => {
    console.log(`Must ${caze._id ? 'UPDATE' : 'CREATE'} a case: `, caze);
    reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.CASE_CREATE_EXTENDED, { newCase: caze, sftpHostId: sftpHost });
    setSelectedCase(undefined);
    handleClose();

    return true;
};

  const columns = useMemo<MRT_ColumnDef<ISerializedCase>[]>(
    () => [
      {
        accessorKey: 'client',
        header: 'Client',
        size: 150,
      },
      {
        accessorKey: 'casename',
        header: 'Casename',
        size: 150,
      },
      {
        accessorKey: 'sircId',
        header: 'SIRCC ID',
        size: 150,
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        size: 150,
      },
      {
        accessorFn: (originalRow: any) => {
          // Get the users and the loading state from the reducer context
          const { users, usersCreating } = reducerContext.state;

          // If users are still being created or not loaded, return a loading state
          if (usersCreating || !users || users.length === 0) {
            return "Loading..."; // You can adjust this to any loading placeholder you prefer
          }

          console.log(`reducer users are: `, users);

          // Find the user by their ID (originalRow.ledBy)
          const user = users.find((user) => user._id === originalRow.ledBy);

          // Return the user's username or a fallback if not found
          return user ? user.username : "User not found";
        },

        //Reducer lenght for loop -> find the  if -> originalRow.Letby = reducerContext.state.users[i]._id
        // return  reducerContext.state.users[i].username
        id: "ledBy",
        header: "Led By",
        size: 150

      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.createdAt),
        id: 'createdAt',
        header: 'Created At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}`, // convert back to string for display
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.updatedAt),
        id: 'updatedAt',
        header: 'Updated At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}`,
      },
      {
        accessorFn: (originalRow) => originalRow.completedAt ? new Date(originalRow.completedAt) : originalRow.completedAt,
        id: 'completedAt',
        header: 'Completed At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          cell.getValue<Date>() ?
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}` :
          'not completed',
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 150,
      }
    ],
    [reducerContext.state],
  );

  let data = reducerContext.state.cases;
  const table = useMaterialReactTable({
    columns,
      data,
      enableColumnOrdering: true,
      enableGrouping: true,
      enableColumnPinning: true,
      enableRowActions: true,
      enableRowSelection: true,
      initialState: {
          showColumnFilters: false,
          showGlobalFilter: true,
          columnPinning: {
              left: ['mrt-row-expand', 'mrt-row-select'],
              right: ['mrt-row-actions'],
          },
          density: "compact"
      },
      renderRowActionMenuItems: ({ closeMenu, row }) => [
        <MenuItem
          key={0}
          onClick={() => {
            setSelectedCase(row.original);
            setOpen(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            alert('DELETING ' + row.getValue('casename'));
            reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.CASE_DELETE, { casename: row.getValue('casename')! });
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      ],

      renderTopToolbarCustomActions: ({ table }) => {
          const handleDelete = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                  alert('DELETING ' + row.getValue('casename'));
                  reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.CASE_DELETE, { casename: row.getValue('casename')! });
              });
          };

          return (
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <Button
                    color="error"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={handleDelete}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
              </Box>
          );
      }
  });

  return <React.Fragment>
    <SpeedDial
        ariaLabel="Add new case"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClick={() => { setSelectedCase(undefined); handleOpen() }}
      >
    </SpeedDial>
    <Typography variant="h4" gutterBottom>Cases</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={table} />
      </LocalizationProvider>
      <NewCaseDialog
          key={selectedCase ? selectedCase._id : ''}
          open={open}
          onClose={handleClose}
          onCaseCreated={handleCaseCreated}
          caze={selectedCase}
          clients={reducerContext.state.clients}
          users={reducerContext.state.users}
          casenames={reducerContext.state.casenames}
          sftpHosts={reducerContext.state.sftpHosts}
      />
  </React.Fragment>
};

export default Cases;