import React from "react";
import { ISerializedCase, ISerializedClient, ISerializedSFTPHost, ISerializedUser } from "../defs/SocketDefs";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Const from "../defs/const";

export type TNewCaseDialogProps = {
    open: boolean;
    caze?: ISerializedCase;
    clients?: ISerializedClient[];
    users?: ISerializedUser[];
    casenames: string[];
    sftpHosts?: ISerializedSFTPHost[];
    onClose: () => void;
    onCaseCreated: (caze: ISerializedCase, sftpHost?: string) => boolean;
}

export const EmptyCase: ISerializedCase = {
    _id: '',
    clientId: '',
    casename: '',
    sircId: '',
    createdBy: '',
    ledBy: '',
    status: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    completedAt: undefined,
    description: '',
    tags: [],
    attorney: false,
    createSFTP: true,
    archiveData: false,
    publishMISP: true,
    legalHold: false,
    notes: ''
};

export const NewCaseDialog: React.FC<TNewCaseDialogProps> = (props) => {
    const [caze, setCaze] = React.useState<ISerializedCase>(Object.assign({}, props.caze || { ...EmptyCase, ledBy: props.users && props.users.length > 0 ? props.users[0]._id : '', clientId: (props.clients && props.clients.length > 0) ? props.clients[0]._id : '' }));
    const [newTag, setNewTag] = React.useState<string>('');
    const [sftpHostId, setSftpHostId] = React.useState<string | undefined>(undefined);

    // console.log(`NewCaseDialog: `, caze);

    const handleSftpHostChange = (event: SelectChangeEvent) => {
        setSftpHostId(event.target.value);
    };

    const handleCaseChange = (key: keyof ISerializedCase) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCaze({ ...caze, [key]: event.target.value });
    };

    const handleSelectChange = (key: keyof ISerializedCase) => (event: SelectChangeEvent) => {
        setCaze({ ...caze, [key]: event.target.value });
    }

    const handleCheckedChange = (key: keyof ISerializedCase) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCaze({ ...caze, [key]: event.target.checked });
    };

    const handleCreateCase = () => {
        setCaze({ ...caze, createdAt: new Date(), updatedAt: new Date() });
        if (props.onCaseCreated(caze, sftpHostId)) setCaze({ ...EmptyCase });
    };

    const handleNewTag = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewTag(event.target.value);
    };

    const handleAddTag = () => {
        let tags = caze.tags || [];
        tags.push(newTag);
        setCaze({ ...caze, tags: tags });
        setNewTag('');
    };

    const handleDeleteChip = (index: number) => {
        let tags = caze.tags || [];
        tags.splice(index, 1);
        setCaze({ ...caze, tags: tags });
    };

    const handleCancel = () => {
        setCaze({ ...EmptyCase });
        setNewTag('');
        setSftpHostId(undefined);
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{caze._id ? `Edit a case` : `Create New Case`}</DialogTitle>
        <DialogContent>
            <TextField
                label="Case name"
                value={caze.casename}
                onChange={handleCaseChange("casename")}
                fullWidth
                sx={{
                    marginBottom: 2,
                    marginTop: 2
                 }}
            />
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="random-case-names-helper-label">Random case names</InputLabel>
                <Select
                    label="Random case names"
                    labelId="random-case-names-helper-label"
                    fullWidth
                    value={caze.casename}
                    onChange={handleSelectChange("casename")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Led by' }}
                >
                    {
                        props.casenames.map((value, index) => (
                            <MenuItem key={value + ' ' + index} value={value}>{value}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="client-helper-label">Client</InputLabel>
                <Select
                    labelId="client-helper-label"
                    fullWidth
                    value={caze.clientId}
                    onChange={handleSelectChange("clientId")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Client' }}
                >
                    {
                        props.clients?.map(client => (
                            <MenuItem key={client._id} value={client._id}>{client.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {(caze._id === undefined || caze._id.length < 1) && <FormControl fullWidth sx={{ }}>
                <InputLabel id="sftp-host-helper-label">SFTP Host</InputLabel>
                <Select
                    labelId="sftp-host-helper-label"
                    fullWidth
                    value={sftpHostId}
                    onChange={handleSftpHostChange}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'SFTP Host' }}
                >
                    {
                        props.sftpHosts?.map((host) => (
                            <MenuItem key={host._id} value={host._id}>{host.hostname}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl> }
            <TextField
                label="SIRCC ID"
                value={caze.sircId}
                onChange={handleCaseChange("sircId")}
                fullWidth
                sx={{
                    marginBottom: 2,
                    // marginTop: 2
                 }}
            />
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="ledBy-helper-label">Led by</InputLabel>
                <Select
                    label="Led by"
                    labelId="ledBy-helper-label"
                    fullWidth
                    value={caze.ledBy}
                    onChange={handleSelectChange("ledBy")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Led by' }}
                >
                    {
                        props.users?.map(user => (
                            <MenuItem key={user._id} value={user._id}>{user.username} {user.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            { caze._id && <FormControl fullWidth sx={{ }}>
                <InputLabel id="status-helper-label">Status</InputLabel>
                <Select
                    label="Status"
                    labelId="status-helper-label"
                    fullWidth
                    value={caze.status}
                    onChange={handleSelectChange("status")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Status' }}
                >
                    {
                        Object.keys(Const.CASE_STATUS).map(status => (
                            <MenuItem key={status} value={status}>{status.split('_').join(' ').toLowerCase()}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl> }
            <TextField
                label="Description"
                multiline
                rows={2}
                value={caze.description}
                onChange={handleCaseChange("description")}
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            <TextField
                label="Notes"
                multiline
                rows={2}
                value={caze.notes}
                onChange={handleCaseChange("notes")}
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: 2 }}
            >
                {caze.tags?.map((tag, index) => (
                    <Chip key={tag + ' ' + index} label={tag} variant="outlined" onDelete={() => handleDeleteChip(index)} />
                ))}
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: 2 }}
            >
                <TextField
                    label="Add tag"
                    value={newTag}
                    onChange={handleNewTag}
                    sx={{ marginBottom: 2, flexGrow: 1 }}
                />
                <Button onClick={() => handleAddTag()}>Add tag</Button>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: 2 }}
            >
                <FormGroup row={true}>
                    <FormControlLabel required control={<Checkbox defaultChecked onChange={handleCheckedChange("createSFTP")} />} label="Create SFTP" />
                    <FormControlLabel control={<Checkbox onChange={handleCheckedChange("attorney")} />} label="Attorney" />
                    <FormControlLabel control={<Checkbox onChange={handleCheckedChange("archiveData")} />} label="Archive data" />
                    <FormControlLabel control={<Checkbox onChange={handleCheckedChange("publishMISP")} />} label="Publish IOCs to MISP" />
                    <FormControlLabel control={<Checkbox color="warning" onChange={handleCheckedChange("legalHold")} />} label="Legal Hold" />
                </FormGroup>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleCreateCase}>{caze._id ? `Save` : `Create`}</Button>
        </DialogActions>
    </Dialog>
    );
}