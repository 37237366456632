import React from "react";
import { useMemo } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

import Const from '../defs/const';
import { ISerializedUser } from '../defs/SocketDefs';
import { NewUserDialog } from "../components/NewUserDialog";
import { ReducerContext } from '../modules/Reducer/ReducerContext';
import { Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

const Users = () => {
  const reducerContext = React.useContext(ReducerContext);
  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<ISerializedUser | undefined>(undefined);
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserCreated = (user: ISerializedUser): boolean => {
    console.log(`Must ${user._id ? 'UPDATE' : 'CREATE'} a user: `, user);
    reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.USER_CREATE, { user });
    setSelectedUser(undefined);
    handleClose();

    return true;
  };

  const columns = useMemo<MRT_ColumnDef<ISerializedUser>[]>(
    () => [
      {
        accessorKey: 'username',
        header: 'Username',
        size: 150,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'role',
        header: 'Role',
        size: 150,
      },
      {
        accessorKey: 'department',
        header: 'Department',
        size: 150,
      },
      {
        accessorKey: 'region',
        header: 'Region',
        size: 150,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.createdAt),
        id: 'createdAt',
        header: 'Created At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}`,
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.updatedAt),
        id: 'updatedAt',
        header: 'Updated At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}`,
      },
      {
        accessorFn: (originalRow) => originalRow.completedAt ? new Date(originalRow.completedAt) : originalRow.completedAt,
        id: 'completedAt',
        header: 'Completed At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          cell.getValue<Date>() ?
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}` :
          'not found',
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 150,
      }
    ],
    [],
  );

  let data = reducerContext.state.users;
  const table = useMaterialReactTable({
    columns,
      data,
      enableColumnOrdering: true,
      enableGrouping: true,
      enableColumnPinning: true,
      enableRowActions: true,
      enableRowSelection: true,
      initialState: {
          showColumnFilters: false,
          showGlobalFilter: true,
          columnPinning: {
              left: ['mrt-row-expand', 'mrt-row-select'],
              right: ['mrt-row-actions'],
          },
          density: 'compact'
      },
      renderRowActionMenuItems: ({ closeMenu, row }) => [
        <MenuItem
          key={0}
          onClick={() => {
            setSelectedUser(row.original);
            setOpen(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            // Send email logic...
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      ],

      renderTopToolbarCustomActions: ({ table }) => {
          const handleDelete = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                  alert('DELETING ' + row.getValue('name'));
              });
          };

          return (
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <Button
                    color="error"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={handleDelete}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
              </Box>
          );
      }
  });

  return <React.Fragment>
      <Typography variant="h4" gutterBottom>Users</Typography>
      <SpeedDial
          ariaLabel="Add new user"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClick={() => { setSelectedUser(undefined); setOpen(true) }}
        >
      </SpeedDial>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={table} />
      </LocalizationProvider>
      <NewUserDialog key={selectedUser ? selectedUser._id : ''} open={open} onClose={handleClose} onUserCreated={handleUserCreated} user={selectedUser} />
  </React.Fragment>
};

export default Users;