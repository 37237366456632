import { Component, createContext, useContext } from 'react';
import { Socket, io } from 'socket.io-client';

import { ReducerContext } from '../Reducer/ReducerContext';
import { ClientToServerEvents, SFTPHostsSetMsg, ServerToClientEvents, UnauthorizedMsg, JWTUpdateMsg, CaseNamesSetMsg, CaseCreatedMsg, UserInfo, ClientsSetMsg, ClientCreatedMsg, UsersSetMsg, UserCreatedMsg, CasesSetMsg, CaseCreatedExtendedMsg, TimeLogsListedMsg, TimeLogCreatedMsg, TimeLogUpdatedMsg, TimeLogDeletedMsg } from '../../defs/SocketDefs';
import Const from '../../defs/const';

export type MySocketProps = {
    children: any,
    uri: string | undefined,
    path: string | undefined,
    autoConnect: boolean
}

export const SocketContext = createContext<Socket<ServerToClientEvents, ClientToServerEvents> | undefined>( undefined);

class MySocket extends Component<MySocketProps> {
    static contextType = ReducerContext;
    private _uri: string | undefined;
    private _path: string | undefined;
    private _autoConnect: boolean;
    private _token: string | undefined;
    private _connecting: boolean;
    state: { client: Socket<ServerToClientEvents, ClientToServerEvents> | undefined };
    context!: React.ContextType<typeof ReducerContext>;

    constructor(props: MySocketProps) {
        super(props);
        this._uri = props.uri;
        this._path = props.path;
        this._autoConnect = props.autoConnect;
        this._token = this.context?.state.jwt;
        this._connecting = false;
        this.state = { client: undefined };

        this.onConnect = this.onConnect.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
        this.initClient = this.initClient.bind(this);
        this.deInitClient = this.deInitClient.bind(this);
        this.initSocketHandlers = this.initSocketHandlers.bind(this);
        this.updateSocketConnection = this.updateSocketConnection.bind(this);
    }

    componentDidMount() {
        this._token = this.context.state.jwt;
        // console.log(`componentDidMount new jwt: ${this.context.state.jwt}, token: ${this._token}, connecting...`);
        if (this._uri && this._path && !this._connecting) {
            this._connecting = this._autoConnect;

            this.initClient();
        }
    }

    componentWillUnmount() {
        // console.log(`componentWillUnmount: disconnecting...`);
        this.deInitClient();
    }

    componentDidUpdate() {
        if (this.context.state.jwt !== this._token) {
            // console.log(`componentDidUpdate new jwt: ${this.context.state.jwt}, token: ${this._token}, RECONNECTING...`);
            this.deInitClient();

            this._token = this.context.state.jwt;
            this._connecting = this._autoConnect;
            this.initClient();
        }
    }

    initClient() {
        if (!this._uri || !this._path) return;
        // console.log(`Socket connecting to ${this._uri}${this._path}, autoConnect: ${this._autoConnect}`);

        let query = this._token ? { token: this._token } : {};

        let client = io(this._uri, {
            path: this._path,
            reconnection: true,
            reconnectionAttempts: 100,
            reconnectionDelay: 1000,
            autoConnect: this._autoConnect,
            query: query,
            agent: false
        });

        this.initSocketHandlers(client);

        this.setState({ client });
    }

    deInitClient() {
        if (this.state.client) {
            if (this.state.client.connected || this._connecting) {
                this.disconnect();
                // console.log(`deInitClient disconnected client...`);
            }

            this.setState({ client: undefined });
        }
    }

    initSocketHandlers(client: Socket<ServerToClientEvents, ClientToServerEvents> | undefined = undefined) {
        if (!client) client = this.state.client;

        if (!client) return;

        client.on(Const.MESSAGE_TOPICS.CONNECT, this.onConnect);
        client.on('connect_error', (error) => {
            // console.log('connect_error', error);
            this._connecting = false;
        });
        client.on(Const.MESSAGE_TOPICS.DISCONNECT, this.onDisconnect);

        client.on(Const.MESSAGE_TOPICS.JWT_UPDATE, (data: JWTUpdateMsg) => {
            // console.log(`UPDATE_JWT: ${data.jwt}`);
            this.context.dispatch({ type: Const.ACTION_TYPES.JWT_UPDATE, payload: data.jwt });
            // this.context.dispatch(new LoginAction({ success: Boolean(data.token), jwt: data.token }));
        });

        client.on(Const.MESSAGE_TOPICS.UNAUTHORIZED, (data: UnauthorizedMsg) => {
            // console.log(`UNAUTHORIZED: ${data.message}`);
            this._autoConnect = false;
            this.context.dispatch({ type: Const.ACTION_TYPES.SOCKET_UNSET, payload: data.message });
        });

        client.on(Const.MESSAGE_TOPICS.SFTP_HOSTS_SET, (data: SFTPHostsSetMsg) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.SFTP_HOSTS_SET, payload: data.hosts });
        });

        client.on(Const.MESSAGE_TOPICS.CASE_NAMES_SET, (data: CaseNamesSetMsg) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.CASE_NAMES_SET, payload: data.names });
            // console.log(`SET_CASE_NAMES: ${JSON.stringify(data)}`);
        });

        client.on(Const.MESSAGE_TOPICS.CASE_CREATED, (data: CaseCreatedMsg) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.CASE_CREATED, payload: data });
            console.log(`CASE_CREATED: ${JSON.stringify(data)}`);
        });

        client.on(Const.MESSAGE_TOPICS.CASE_CREATED_EXTENDED, (data: CaseCreatedExtendedMsg) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.CASE_CREATED_EXTENDED, payload: data });
            console.log(`CASE_CREATED_EXTENDED: ${JSON.stringify(data)}`);
        });

        client.on(Const.MESSAGE_TOPICS.SFTP_USER_CREATED, (data: UserInfo) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.SFTP_DETAILS_SET, payload: data });
            // console.log(`SFTP_USER_CREATED: ${JSON.stringify(data)}`);
        });

        client.on(Const.MESSAGE_TOPICS.CLIENTS_SET, (data: ClientsSetMsg) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.CLIENTS_SET, payload: data });
            // console.log(`SET_CLIENTS: ${JSON.stringify(data)}`);
        });

        client.on(Const.MESSAGE_TOPICS.USERS_SET, (data: UsersSetMsg) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.USERS_SET, payload: data });
            // console.log(`SET_USERS: ${JSON.stringify(data)}`);
        });

        client.on(Const.MESSAGE_TOPICS.CASES_SET, (data: CasesSetMsg) => {
            this.context.dispatch({ type: Const.ACTION_TYPES.CASES_SET, payload: data });
            // console.log(`SET_CASES: ${JSON.stringify(data)}`);
        });

        client.on(Const.MESSAGE_TOPICS.CLIENT_CREATED, (data: ClientCreatedMsg) => {
            // console.log(`CLIENT_CREATED: ${JSON.stringify(data)}`);

            if (data.success)
                this.context.dispatch({ type: Const.ACTION_TYPES.CLIENT_CREATED, payload: data });
            else
                console.log(`CLIENT_CREATED failed: ${data.message}`);
        });

        client.on(Const.MESSAGE_TOPICS.USER_CREATED, (data: UserCreatedMsg) => {
            // console.log(`USER_CREATED: ${JSON.stringify(data)}`);

            if (data.success)
                this.context.dispatch({ type: Const.ACTION_TYPES.USER_CREATED, payload: data });
            else
                console.log(`USER_CREATED failed: ${data.message}`);
        });

        client.on(Const.MESSAGE_TOPICS.TIME_LOGS_LISTED, (data: TimeLogsListedMsg) => {
            // console.log(`LIST_TIME_LOGS_RE: ${JSON.stringify(data)}`);
            this.context.dispatch({ type: Const.ACTION_TYPES.TIME_LOGS_SET, payload: data });
        });

        client.on(Const.MESSAGE_TOPICS.TIME_LOG_CREATED, (data: TimeLogCreatedMsg) => {
            console.log(`CREATE_TIME_LOG_RE: ${JSON.stringify(data)}`);
            this.context.dispatch({ type: Const.ACTION_TYPES.TIME_LOG_CREATED, payload: data });
        });

        client.on(Const.MESSAGE_TOPICS.TIME_LOG_UPDATED, (data: TimeLogUpdatedMsg) => {
            console.log(`UPDATE_TIME_LOG_RE: ${JSON.stringify(data)}`);
            this.context.dispatch({ type: Const.ACTION_TYPES.TIME_LOG_UPDATED, payload: data });
        });

        client.on(Const.MESSAGE_TOPICS.TIME_LOG_DELETED, (data: TimeLogDeletedMsg) => {
            console.log(`DELETE_TIME_LOG_RE: ${JSON.stringify(data)}`);
            this.context.dispatch({ type: Const.ACTION_TYPES.TIME_LOG_DELETED, payload: data });
        });

        client.onAny((eventName, args) => {
            // console.log(`Unknown event received: ${eventName} with data: ${JSON.stringify(args)}`);
        });
    }

    updateSocketConnection() {
        let client = this.state.client;

        if (!client)
            this.initClient();

        if (client && !this._connecting && !client.connected) {
            this._connecting = true;
            client.connect();
        }
    }

    render() {
        return (
            <SocketContext.Provider value={this.state.client}>
                {this.props.children}
            </SocketContext.Provider>
        );
    }

    // new SetSocketAction(this.state.client)
    onConnect() {
        if (this.state.client) this.context.dispatch({ type: Const.ACTION_TYPES.SOCKET_SET, payload: this.state.client});
        this._connecting = false;
    }

    onDisconnect(reason: string) {
        console.log('Socket is disconnected:', reason);
        this.context.dispatch({ type: Const.ACTION_TYPES.SOCKET_UNSET, payload: undefined });
        // if (reason === 'io server disconnect' || reason === 'transport close') {
        //     setTimeout(() => this.updateSocketConnection(), 500);
        // }
    }

    disconnect(): void {
        let client = this.state.client;

        this._connecting = false;
        client?.disconnect();
    }

    get connected(): boolean {
        return this.state.client?.connected || false;
    }

    get uri(): string | undefined {
        return this._uri;
    }

    set uri(v: string | undefined) {
        this._uri = v;
    }

    get path(): string | undefined {
        return this._path;
    }

    set path(v: string | undefined) {
        this._path = v;
    }

    get autoConnect(): boolean {
        return this._autoConnect;
    }

    set autoConnect(v: boolean) {
        this._autoConnect = v;
    }

    get token(): string | undefined {
        return this._token;
    }

    set token(v: string | undefined) {
        if (this._token !== v) {
            // console.log(`Socket - TOKEN CHANGED, reconnecting...`);
            this._token = v;
            this.initClient();
            this.updateSocketConnection();
        }
    }

    get connecting(): boolean {
        return this._connecting;
    }
}

export default MySocket;
