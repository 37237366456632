import React from "react";
import { ISerializedCase, ISerializedCaseTimeLogs, ISerializedClient, ISerializedSFTPHost, ISerializedUser } from "../defs/SocketDefs";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Const, { EnumTimeLogType } from "../defs/const";

export type TNewTimeLogDialogProps = {
    open: boolean;
    cases?: ISerializedCase[];
    timeLog?: ISerializedCaseTimeLogs;
    users?: ISerializedUser[];
    thisUsername: string;
    onClose: () => void;
    onTimeLogCreated: (timeLog: ISerializedCaseTimeLogs) => boolean;
}

export const EmptyTimeLog: ISerializedCaseTimeLogs = {
    _id: '',
    caseId: '',
    casename: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    duration: 0,
    logType: '',
    userId: '',
    username: '',
    addedById: '',
    addedByUsername: ''
};

export const NewTimeLogDialog: React.FC<TNewTimeLogDialogProps> = (props) => {
    const [timeLog, setTimeLog] = React.useState<ISerializedCaseTimeLogs>(Object.assign({}, props.timeLog || {
        ...EmptyTimeLog,
        userId: props.users && props.users.length > 0 ? props.users[0]._id : '',
        username: props.users && props.users.length > 0 ? props.users[0].username : '',
        caseId: (props.cases && props.cases.length > 0) ? props.cases[0]._id : '',
        casename: props.cases && props.cases.length > 0 ? props.cases[0].casename : '',
        addedById: props.thisUsername
    }));

    // console.log(`NewCaseDialog: `, timeLog);

    const handleTimeLogChange = (key: keyof ISerializedCaseTimeLogs) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeLog({ ...timeLog, [key]: event.target.value });
    };

    const handleSelectChange = (key: keyof ISerializedCaseTimeLogs) => (event: SelectChangeEvent) => {
        if (key === 'caseId') {
            let casename = props.cases?.find(caze => caze._id === event.target.value)?.casename || '';
            setTimeLog({ ...timeLog, [key]: event.target.value, casename: casename });
            // console.log(`Selected case: `, event.target.value, casename);
        } else if (key === 'userId') {
            let username = props.users?.find(user => user._id === event.target.value)?.username || '';
            setTimeLog({ ...timeLog, [key]: event.target.value, username: username });
            // console.log(`Selected user: `, event.target.value, username);
        } else {
            setTimeLog({ ...timeLog, [key]: event.target.value });
            // console.log(`Selected: `, event.target.value, event.target.name);
        }
    }

    const handleCreateCase = () => {
        let newTimeLog: ISerializedCaseTimeLogs;
        if (timeLog._id) newTimeLog = { ...timeLog, updatedAt: new Date() };
        else newTimeLog = { ...timeLog, createdAt: new Date(), updatedAt: new Date() };
        setTimeLog(newTimeLog);

        if (props.onTimeLogCreated(newTimeLog)) setTimeLog({ ...EmptyTimeLog });
    };

    const handleCancel = () => {
        setTimeLog({ ...EmptyTimeLog });
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{timeLog._id ? `Edit a time log` : `Create a new time log`}</DialogTitle>
        <DialogContent>
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="cases-helper-label">Cases</InputLabel>
                <Select
                    label="Cases"
                    labelId="cases-helper-label"
                    fullWidth
                    value={timeLog.caseId}
                    onChange={handleSelectChange("caseId")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Led by' }}
                >
                    {
                        props.cases?.map((caze) => (
                            <MenuItem key={caze.casename} value={caze._id}>{caze.casename}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="user-helper-label">User</InputLabel>
                <Select
                    labelId="user-helper-label"
                    fullWidth
                    value={timeLog.userId}
                    onChange={handleSelectChange("userId")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'User' }}
                >
                    {
                        props.users?.map(user => (
                            <MenuItem key={user._id} value={user._id}>{user.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {/* {timeLog._id === undefined && <FormControl fullWidth sx={{ }}>
                <InputLabel id="sftp-host-helper-label">SFTP Host</InputLabel>
                <Select
                    labelId="sftp-host-helper-label"
                    fullWidth
                    value={sftpHostId}
                    onChange={handleSftpHostChange}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'SFTP Host' }}
                >
                    {
                        props.sftpHosts?.map((host) => (
                            <MenuItem key={host._id} value={host._id}>{host.hostname}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl> } */}
            <FormControl fullWidth sx={{ }}>
                <InputLabel id="log-type-helper-label">Log type</InputLabel>
                <Select
                    label="Log type"
                    labelId="log-type-helper-label"
                    fullWidth
                    value={timeLog.logType}
                    onChange={handleSelectChange("logType")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Log Type' }}
                >
                    {
                        Object.values(EnumTimeLogType).map((value) => (
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <TextField
                label="Duration"
                value={timeLog.duration}
                onChange={handleTimeLogChange("duration")}
                type="number"
                fullWidth
                sx={{
                    marginBottom: 2,
                    // marginTop: 2
                 }}
            />
            {/* <FormControl fullWidth sx={{ }}>
                <InputLabel id="ledBy-helper-label">Led by</InputLabel>
                <Select
                    label="Led by"
                    labelId="ledBy-helper-label"
                    fullWidth
                    value={timeLog.ledBy}
                    onChange={handleSelectChange("ledBy")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Led by' }}
                >
                    {
                        props.users?.map(user => (
                            <MenuItem key={user._id} value={user._id}>{user.username} {user.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl> */}
            {/* { timeLog._id && <FormControl fullWidth sx={{ }}>
                <InputLabel id="status-helper-label">Status</InputLabel>
                <Select
                    label="Status"
                    labelId="status-helper-label"
                    fullWidth
                    value={timeLog.status}
                    onChange={handleSelectChange("status")}
                    sx={{ marginBottom: 2 }}
                    inputProps={{ 'aria-label': 'Status' }}
                >
                    {
                        Object.keys(Const.CASE_STATUS).map(status => (
                            <MenuItem key={status} value={status}>{status.split('_').join(' ').toLowerCase()}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl> }
            <TextField
                label="Description"
                multiline
                rows={2}
                value={timeLog.description}
                onChange={handleTimeLogChange("description")}
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            <TextField
                label="Notes"
                multiline
                rows={2}
                value={timeLog.notes}
                onChange={handleTimeLogChange("notes")}
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: 2 }}
            >
                {timeLog.tags?.map((tag, index) => (
                    <Chip key={tag + ' ' + index} label={tag} variant="outlined" onDelete={() => handleDeleteChip(index)} />
                ))}
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: 2 }}
            >
                <TextField
                    label="Add tag"
                    value={newTag}
                    onChange={handleNewTag}
                    sx={{ marginBottom: 2, flexGrow: 1 }}
                />
                <Button onClick={() => handleAddTag()}>Add tag</Button>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: 2 }}
            >
                <FormGroup row={true}>
                    <FormControlLabel required control={<Checkbox defaultChecked onChange={handleCheckedChange("createSFTP")} />} label="Create SFTP" />
                    <FormControlLabel control={<Checkbox onChange={handleCheckedChange("attorney")} />} label="Attorney" />
                    <FormControlLabel control={<Checkbox onChange={handleCheckedChange("archiveData")} />} label="Archive data" />
                    <FormControlLabel control={<Checkbox onChange={handleCheckedChange("publishMISP")} />} label="Publish IOCs to MISP" />
                    <FormControlLabel control={<Checkbox color="warning" onChange={handleCheckedChange("legalHold")} />} label="Legal Hold" />
                </FormGroup>
            </Stack> */}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleCreateCase}>{timeLog._id ? `Save` : `Create`}</Button>
        </DialogActions>
    </Dialog>
    );
}