import React from "react";
import { useMemo } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
    MaterialReactTable,
    MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';

import Const from '../defs/const';
import { ISerializedClient } from '../defs/SocketDefs';
import { NewClientDialog } from "../components/NewClientDialog";
import { ReducerContext } from '../modules/Reducer/ReducerContext';
import { Box, Button, lighten, ListItemIcon, MenuItem } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

const Clients = () => {
  const reducerContext = React.useContext(ReducerContext);
  const [open, setOpen] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState<ISerializedClient | undefined>(undefined);

  const handleOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const handleClientCreated = (client: ISerializedClient): boolean => {
      console.log(`Must ${client._id ? 'UPDATE' : 'CREATE'} a client: `, client);
      reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.CLIENT_CREATE, { client });
      setSelectedClient(undefined);
      handleClose();

      return true;
  };

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<ISerializedClient>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'clientType',
        header: 'Type',
        size: 150,
      },
      {
        accessorKey: 'usedHours', //normal accessorKey
        header: 'Used Hours',
        size: 75,
      },
      {
        accessorKey: 'totalHours',
        header: 'Total Hours',
        size: 75,
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.hoursResetAt),
        id: 'hoursResetAt',
        header: 'Hours Reset At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}`,
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.createdAt),
        id: 'createdAt',
        header: 'Created At',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) =>
          `${cell.getValue<Date>().toLocaleDateString()} ${cell
            .getValue<Date>()
            .toLocaleTimeString()}`,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 150,
      }
    ],
    [],
  );

  let data = reducerContext.state.clients;
  const table = useMaterialReactTable({
      columns,
      data,
      enableColumnOrdering: true,
      enableGrouping: true,
      enableColumnPinning: true,
      enableRowActions: true,
      enableRowSelection: true,
      initialState: {
          showColumnFilters: false,
          showGlobalFilter: true,
          columnPinning: {
              left: ['mrt-row-expand', 'mrt-row-select'],
              right: ['mrt-row-actions'],
          },
          density: "compact"
      },
      renderRowActionMenuItems: ({ closeMenu, row }) => [
        <MenuItem
          key={0}
          onClick={() => {
            setSelectedClient(row.original);
            setOpen(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            // Send email logic...
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      ],
      // renderTopToolbar: ({ table }) => {
      //   const handleDeactivate = () => {
      //     table.getSelectedRowModel().flatRows.map((row) => {
      //       alert('deactivating ' + row.getValue('name'));
      //     });
      //   };

      //   const handleActivate = () => {
      //     table.getSelectedRowModel().flatRows.map((row) => {
      //       alert('activating ' + row.getValue('name'));
      //     });
      //   };

      //   const handleContact = () => {
      //     table.getSelectedRowModel().flatRows.map((row) => {
      //       alert('contact ' + row.getValue('name'));
      //     });
      //   };

      //   return (
      //     <Box
      //       sx={(theme) => ({
      //         backgroundColor: lighten(theme.palette.background.default, 0.05),
      //         display: 'flex',
      //         gap: '0.5rem',
      //         p: '8px',
      //         justifyContent: 'space-between',
      //       })}
      //     >

      //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
      //         {/* import MRT sub-components */}
      //         <MRT_GlobalFilterTextField table={table} />
      //         <MRT_ToggleFiltersButton table={table} />
      //       </Box>
      //       <Box>
      //         <Box sx={{ display: 'flex', gap: '0.5rem' }}>
      //           <Button
      //             color="error"
      //             disabled={!table.getIsSomeRowsSelected()}
      //             onClick={handleDeactivate}
      //             variant="contained"
      //           >
      //             Deactivate
      //           </Button>
      //           <Button
      //             color="success"
      //             disabled={!table.getIsSomeRowsSelected()}
      //             onClick={handleActivate}
      //             variant="contained"
      //           >
      //             Activate
      //           </Button>
      //           <Button
      //             color="info"
      //             disabled={!table.getIsSomeRowsSelected()}
      //             onClick={handleContact}
      //             variant="contained"
      //           >
      //             Contact
      //           </Button>
      //         </Box>
      //       </Box>
      //     </Box>
      //   );
  
      // },
      renderTopToolbarCustomActions: ({ table }) => {
          const handleDelete = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                  alert('DELETING ' + row.getValue('name'));
              });
          };

          const handleActivate = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                  alert('activating ' + row.getValue('name'));
              });
          };

          const handleContact = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                  alert('contact ' + row.getValue('name'));
              });
          };

          return (
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <Button
                    color="error"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={handleDelete}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                  <Button
                    color="success"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={handleActivate}
                    variant="contained"
                  >
                    Activate
                  </Button>
                  <Button
                    color="info"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={handleContact}
                    variant="contained"
                  >
                    Contact
                  </Button>
              </Box>
          );
      }
  });

  return <React.Fragment>
      <SpeedDial
          ariaLabel="Add new client"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClick={() => { setSelectedClient(undefined); setOpen(true) }}
        >
          {/* {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
            />
          ))} */}
      </SpeedDial>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={table} />
      </LocalizationProvider>
      <NewClientDialog key={selectedClient ? selectedClient._id : ''} open={open} onClose={handleClose} onClientCreated={handleClientCreated} client={selectedClient} />
  </React.Fragment>
};

export default Clients;
