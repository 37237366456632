import { Socket } from 'socket.io-client';
import { ClientToServerEvents, ISerializedCase, ISerializedCaseTimeLogs, ISerializedClient, ISerializedSFTPHost, ISerializedUser, ServerToClientEvents } from '../../defs/SocketDefs';
import Const from '../../defs/const';
import jwtDecode, { TJWTToken } from '../jwtDecode';

export interface IMyReducerStateType {
    jwt: string | undefined;
    decodedJWT: TJWTToken | undefined;
    client: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;
    errorMessage: string | undefined;
    loading: boolean;
    loadingCaseNames: boolean;
    loadingSFTPHosts: boolean;
    casenames: string[];
    selectedCaseNameIndex: number;
    customCaseName: string;
    sirccid: string;
    sftpusername: string;
    sftppassword: string;
    sftphostname: string;
    dialogopen: boolean;
    emailtext: string;
    casesNew: boolean;
    existingCases: string[];
    sftpHosts: ISerializedSFTPHost[];
    selectedSFTPHost: string;
    caseCreating: boolean;
    clients: ISerializedClient[];
    clientsCreating: boolean;
    users: ISerializedUser[];
    usersCreating: boolean;
    cases: ISerializedCase[];
    timeLogs: ISerializedCaseTimeLogs[];
    loadingTimeLogs: boolean;
    isLoggedIn(): boolean;
    restore(): void;
}

export type IMyReducerType = {
    state: IMyReducerStateType;
    reducer(state: IMyReducerStateType, action: ActionType): IMyReducerStateType;
}

export type ActionType = {
    type: string;
    payload: any;
}

const MyReducer: IMyReducerType = {
    state: {
        jwt: undefined,
        decodedJWT: undefined,
        client: undefined,
        errorMessage: undefined,
        loading: false,
        loadingCaseNames: false,
        loadingSFTPHosts: false,
        casenames: [],
        selectedCaseNameIndex: -1,
        customCaseName: '',
        sirccid: '',
        sftpusername: '',
        sftppassword: '',
        sftphostname: '',
        dialogopen: false,
        emailtext: '',
        casesNew: true,
        existingCases: [],
        sftpHosts: [],
        selectedSFTPHost: '',
        caseCreating: false,
        clients: [],
        clientsCreating: false,
        users: [],
        usersCreating: false,
        cases: [],
        timeLogs: [],
        loadingTimeLogs: false,
        isLoggedIn(): boolean {
            return this.jwt !== undefined;
        },
        restore: function() {
            let jwToken = localStorage.getItem(Const.LOCAL_STORAGE.TOKEN);
            if (jwToken) {
                this.jwt = jwToken;
                this.decodedJWT = jwtDecode(jwToken);
                // console.log(`Restore decoded: ${JSON.stringify(this.decodedJWT)}`);
            }
        }
    },

    reducer(state: IMyReducerStateType, action: ActionType): IMyReducerStateType {
        let newCases: ISerializedCase[];
        let caseIndex: number;
        let newTimeLogs: ISerializedCaseTimeLogs[];
        let logIndex: number;

        switch (action.type) {
            case Const.ACTION_TYPES.JWT_UPDATE:
                window.localStorage.setItem(Const.LOCAL_STORAGE.TOKEN, action.payload);
                // console.log('Reducer updated the JWT:', action.payload);
                return {
                    ...state,
                    jwt: action.payload,
                    decodedJWT: jwtDecode(action.payload)
                };
            case Const.ACTION_TYPES.CASE_CREATING_SET:
                return {
                    ...state,
                    caseCreating: action.payload
                };
            case Const.ACTION_TYPES.CASE_CREATED:
                console.log(`Reducer.CASE_CREATED ${action.payload.caseName} created with status ${action.payload.status}`);
                newCases = [...state.cases];
                caseIndex = newCases.findIndex((c) => c.casename === action.payload.caseName);
                if (caseIndex >= 0) {
                    newCases[caseIndex] = { ...newCases[caseIndex], status: action.payload.status };
                    console.log(`Case ${action.payload.caseName} updated to `, newCases[caseIndex]);
                }
                else {
                    newCases.push(action.payload);
                    console.log(`Case ${action.payload.caseName} added to cases`);
                }

                return {
                    ...state,
                    cases: newCases,
                    caseCreating: false
                };
            case Const.ACTION_TYPES.CASE_CREATED_EXTENDED:
                // add the new case in action.payload to the cases array
                console.log(`Reducer.CASE_CREATED_EXTENDED `, action.payload.newCase);
                newCases = [...state.cases];
                caseIndex = newCases.findIndex((c) => c.casename === action.payload.newCase.casename);
                if (caseIndex >= 0) {
                    newCases[caseIndex] = action.payload.newCase;
                    console.log(`Case ${action.payload.newCase.casename} at index ${caseIndex} updated to `, newCases[caseIndex]);
                }
                else {
                    newCases.push(action.payload.newCase);
                    console.log(`Case ${action.payload.newCase.casename} added to cases`);
                }

                return {
                    ...state,
                    cases: newCases,
                    caseCreating: false
                };
            case Const.ACTION_TYPES.SOCKET_SET:
                return {
                    ...state,
                    client: action.payload
                };
            case Const.ACTION_TYPES.SOCKET_UNSET:
                return {
                    ...state,
                    client: undefined,
                    errorMessage: action.payload
                };
            case Const.ACTION_TYPES.SFTP_HOSTS_SET:
                console.log(`Setting SFTP hosts: ${JSON.stringify(action.payload)} and setting selectedSFTPHost to ${action.payload.length > 0 ? action.payload[0].hostname : ''}`);
                return {
                    ...state,
                    sftpHosts: action.payload,
                    loadingSFTPHosts: false,
                    selectedSFTPHost: action.payload.length > 0 ? action.payload[0].hostname : '',
                    sftphostname: action.payload.length > 0 ? action.payload[0].hostname : ''
                };
            case Const.ACTION_TYPES.CASE_NAMES_SET:
                return {
                    ...state,
                    casenames: action.payload,
                    loadingCaseNames: false
                };
            case Const.ACTION_TYPES.CASE_NAMES_LOADING_SET:
                return {
                    ...state,
                    loadingCaseNames: action.payload
                };
            case Const.ACTION_TYPES.SFTP_HOSTS_LOADING_SET:
                return {
                    ...state,
                    loadingSFTPHosts: action.payload
                };
            case Const.ACTION_TYPES.CUSTOM_CASE_NAME_SET:
                return {
                    ...state,
                    customCaseName: action.payload,
                    sftpusername: action.payload,
                    selectedCaseNameIndex: -1
                };
            case Const.ACTION_TYPES.SIRCC_CASE_ID_SET:
                return {
                    ...state,
                    sirccid: action.payload
                };
            case Const.ACTION_TYPES.SELECTED_CASE_NAME_INDEX_SET:
                return {
                    ...state,
                    customCaseName: '',
                    selectedCaseNameIndex: action.payload,
                    sftpusername: state.casenames[action.payload]
                };
            case Const.ACTION_TYPES.SELECTED_SFTP_HOST_SET:
                return {
                    ...state,
                    selectedSFTPHost: action.payload,
                    sftphostname: action.payload
                };
            case Const.ACTION_TYPES.DIALOG_OPEN_SET:
                return {
                    ...state,
                    dialogopen: action.payload
                };
            case Const.ACTION_TYPES.SFTP_DETAILS_SET:
                return {
                    ...state,
                    emailtext: Const.EMAIL_TEMPLATE
                    .replace("%SIRCID%", state.sirccid ? state.sirccid : "The case")
                    .replace("%CASENAME%", state.customCaseName ? state.customCaseName : state.casenames[state.selectedCaseNameIndex])
                    .replace("%SFTPHOSTNAME%", state.sftphostname.endsWith('.sftp.dfibg.com') ? state.sftphostname : state.sftphostname + '.sftp.dfibg.com')
                    .replace("%SFTPUSERNAME%", state.sftpusername)
                    .replace("%SFTPPASSWORD%", state.sftppassword)
                    .replace("%EMAILADDRESS%", state.decodedJWT?.payload.username ? state.decodedJWT.payload.username : "your email address"),
                    sftpusername: action.payload.username,
                    sftppassword: action.payload.password,
                    sftphostname: action.payload.hostname
                };
            case Const.ACTION_TYPES.CLIENTS_SET:
                return {
                    ...state,
                    clients: action.payload.clients
                };
            case Const.ACTION_TYPES.CLIENTS_LOADING_SET:
                return {
                    ...state,
                    clientsCreating: action.payload
                };
            case Const.ACTION_TYPES.CLIENT_CREATED:
                let newClients = [...state.clients];
                let clientIndex = newClients.findIndex((client) => client._id === action.payload.client._id);
                if (clientIndex >= 0)
                    newClients[clientIndex] = action.payload.client;
                else
                    newClients.push(action.payload.client);

                return {
                    ...state,
                    clients: newClients,
                    clientsCreating: false
                };
            case Const.ACTION_TYPES.USERS_SET:
                return {
                    ...state,
                    users: action.payload.users
                };
            case Const.ACTION_TYPES.USERS_LOADING_SET:
                return {
                    ...state,
                    usersCreating: action.payload
                };
            case Const.ACTION_TYPES.USER_CREATED:
                let newUsers = [...state.users];
                let userIndex = newUsers.findIndex((user) => user._id === action.payload.user._id);
                if (userIndex >= 0)
                    newUsers[userIndex] = action.payload.user;
                else
                    newUsers.push(action.payload.user);

                return {
                    ...state,
                    users: newUsers,
                    usersCreating: false
                };
            case Const.ACTION_TYPES.CASES_SET:
                return {
                    ...state,
                    cases: action.payload.cases
                };
            case Const.ACTION_TYPES.TIME_LOGS_LOADING_SET:
                return {
                    ...state,
                    loadingTimeLogs: action.payload
                };
            case Const.ACTION_TYPES.TIME_LOGS_SET:
                return {
                    ...state,
                    timeLogs: action.payload.logs,
                    loadingTimeLogs: false
                };
            case Const.ACTION_TYPES.TIME_LOG_CREATED:
            case Const.ACTION_TYPES.TIME_LOG_UPDATED:
                    newTimeLogs = [...state.timeLogs];
                logIndex = newTimeLogs.findIndex((log) => log._id === action.payload._id);

                if (logIndex >= 0)
                    newTimeLogs[logIndex] = action.payload;
                else
                    newTimeLogs.push(action.payload);

                return {
                    ...state,
                    timeLogs: newTimeLogs
                };
            case Const.ACTION_TYPES.TIME_LOG_DELETED:
                newTimeLogs = [...state.timeLogs];
                logIndex = newTimeLogs.findIndex((log) => log._id === action.payload._id);

                if (logIndex >= 0) {
                    newTimeLogs.splice(logIndex, 1);

                    return {
                        ...state,
                        timeLogs: newTimeLogs
                    };
                }
                else
                    return state;
            default:
                return state;
        }
    }
}

export default MyReducer;