import React, { useMemo } from "react";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';

import Const from '../defs/const';
import { TimeLogCreateMsg, ISerializedCaseTimeLogs, TimeLogUpdateMsg } from '../defs/SocketDefs';
import { ReducerContext } from '../modules/Reducer/ReducerContext';
import { Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon, FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { NewTimeLogDialog } from "../components/NewTimeLogDialog";
import { mkConfig, generateCsv, download } from 'export-to-csv';

const TimeLogs = () => {
  const reducerContext = React.useContext(ReducerContext);
  const [open, setOpen] = React.useState(false);
  const [selectedTimeLog, setSelectedTimeLog] = React.useState<ISerializedCaseTimeLogs | undefined>(undefined);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const handleOpen = () => {
    console.log('Opening the dialog...');
    if (reducerContext.state.timeLogs.length <= 0) {
      console.log('Requesting the time logs...');
      reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.TIME_LOGS_LIST, {});
      reducerContext.dispatch({ type: Const.ACTION_TYPES.TIME_LOGS_LOADING_SET, payload: true });
    }

    setOpen(true);
  };

  const handleClose = () => {
    console.log('Closing the dialog...');
    setOpen(false);
  };

  const handleTimeLogCreated = (log: ISerializedCaseTimeLogs): boolean => {
    console.log(`Must ${log._id ? 'UPDATE' : 'CREATE'} a time log: `, log);
    log['addedById'] = reducerContext.state.decodedJWT?.payload.id || '';
    log['addedByUsername'] = reducerContext.state.decodedJWT?.payload.username || '';
    if (log._id) {
      console.log('Updating the time log...');
      reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.TIME_LOG_UPDATE, log as TimeLogUpdateMsg);
    } else {
      reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.TIME_LOG_CREATE, log as TimeLogCreateMsg);
    }
    setSelectedTimeLog(undefined);
    handleClose();

    return true;
  };

  const handleExportRows = (rows: MRT_Row<ISerializedCaseTimeLogs>[]) => {
    const rowData = rows.map((row) => {
      const original = row.original;
  
      const transformedRow = Object.entries(original).reduce((acc, [key, value]) => {
        acc[key] = value instanceof Date ? value.toISOString() : value;
        return acc;
      }, {} as Record<string, any>);
  
      return transformedRow;
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const transformedData = data.map((item) => {
      const transformedItem = Object.entries(item).reduce((acc, [key, value]) => {
        acc[key] = value instanceof Date ? value.toISOString() : value;
        return acc;
      }, {} as Record<string, any>);
  
      return transformedItem;
    });
    const csv = generateCsv(csvConfig)(transformedData);
    download(csvConfig)(csv);
  };

  const columns = useMemo<MRT_ColumnDef<ISerializedCaseTimeLogs>[]>(
      () => [
          {
            accessorKey: 'casename',
            header: 'Case name',
            size: 150,
          },
          {
            accessorKey: 'username',
            header: 'User',
            size: 150,
          },
          {
            accessorKey: 'logType',
            header: 'Log type',
            size: 150,
          },
          {
            accessorKey: 'duration',
            header: 'Duration',
            size: 150,
          },
          {
            accessorKey: 'addedByUsername',
            header: 'Added by',
            size: 150,
          },

          {
            accessorFn: (originalRow) => (originalRow && originalRow.createdAt) ? new Date(originalRow.createdAt) : new Date(),
            id: 'createdAt',
            header: 'Created At',
            filterVariant: 'datetime-range',
            Cell: ({ cell }) =>
              `${cell.getValue<Date>().toLocaleDateString()} ${cell
                .getValue<Date>()
                .toLocaleTimeString()}`, // convert back to string for display
          },
          {
            accessorFn: (originalRow) => (originalRow && originalRow.updatedAt) ? new Date(originalRow.updatedAt) : new Date(),
            id: 'updatedAt',
            header: 'Updated At',
            filterVariant: 'datetime-range',
            Cell: ({ cell }) =>
              `${cell.getValue<Date>().toLocaleDateString()} ${cell
                .getValue<Date>()
                .toLocaleTimeString()}`,
          }
      ],
      [],
  );

  console.log('TimeLogs: ', reducerContext.state.timeLogs);
  let data = reducerContext.state.timeLogs;
  const table = useMaterialReactTable({
      columns,
      data,
      enableColumnOrdering: true,
      enableGrouping: true,
      enableColumnPinning: true,
      enableRowActions: true,
      enableRowSelection: true,
      initialState: {
          showColumnFilters: false,
          showGlobalFilter: true,
          columnPinning: {
              left: ['mrt-row-expand', 'mrt-row-select'],
              right: ['mrt-row-actions'],
          },
          density: "compact"
      },

      renderRowActionMenuItems: ({ closeMenu, row }) => [
        <MenuItem
          key={0}
          onClick={() => {
            setSelectedTimeLog(row.original);
            setOpen(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            reducerContext.state.client?.emit(Const.MESSAGE_TOPICS.TIME_LOG_DELETE, { _id: row.original._id });
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      ],

      renderTopToolbarCustomActions: ({ table }) => {
          const handleDelete = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                  alert('DELETING ' + row.getValue('name'));
              });
          };

          return (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '16px',
                      padding: '8px',
                      flexWrap: 'wrap',
                    }}
                  >
                  <Button
                    color="error"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={handleDelete}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                  <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                  >
                    Export All Data
                  </Button>
                  <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                  >
                    Export All Rows
                  </Button>
                  <Button
                    disabled={table.getRowModel().rows.length === 0}
                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                    onClick={() => handleExportRows(table.getRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                  >
                    Export Page Rows
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                  >
                    Export Selected Rows
                  </Button>
                  </Box>
            );
        }
  });

  return <React.Fragment>
    <SpeedDial
        ariaLabel="Add new time log"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClick={() => { setSelectedTimeLog(undefined); handleOpen() }}
      >
    </SpeedDial>
    <Typography variant="h4" gutterBottom>Time logs</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={table} />
      </LocalizationProvider>
      <NewTimeLogDialog
          key={selectedTimeLog ? selectedTimeLog._id : ''}
          open={open}
          onClose={handleClose}
          onTimeLogCreated={handleTimeLogCreated}
          timeLog={selectedTimeLog}
          users={reducerContext.state.users}
          cases={reducerContext.state.cases}
          thisUsername={reducerContext.state.decodedJWT?.payload.username || ''}
      />
  </React.Fragment>
};

export default TimeLogs;