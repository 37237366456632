import React from "react";
import { ReducerContext } from './modules/Reducer/ReducerContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Cases from "./pages/Cases";
import SFTP from "./pages/SFTP";
import Users from "./pages/Users";
import Login from "./pages/Login";
import Clients from "./pages/Clients";
import TimeLogs from "./pages/TimeLogs";

export default function App() {
  const reducerContext = React.useContext(ReducerContext);
  let items: JSX.Element[] = [];

  if (!reducerContext.state.decodedJWT) {
    items.push(<Route index element={<Login />} />);
  } else {
    items.push(
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="clients" element={<Clients />} />
        <Route path="cases" element={<Cases />} />
        <Route path="sftp" element={<SFTP />} />
        <Route path="users" element={<Users />} />
        <Route path="timelogs" element={<TimeLogs />} />
      </Route>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        {items}
      </Routes>
    </BrowserRouter>
  );
}