import { createContext, Dispatch, useEffect, useReducer } from "react";
import MyReducer, { IMyReducerStateType, ActionType } from "./Reducer";

interface InitContextProps {
    state: IMyReducerStateType,
    dispatch: Dispatch<ActionType>
}

type ProviderProps = {
    children: any
}

export const ReducerContext = createContext<InitContextProps>({} as InitContextProps);

export const Provider = ({ children }: ProviderProps) => {
    if (MyReducer.state.jwt === undefined || MyReducer.state.decodedJWT === undefined) {
        MyReducer.state.restore();
    }

    const [state, dispatch] = useReducer(MyReducer.reducer, MyReducer.state);
    let value = { state, dispatch };

    useEffect(() => {
        // console.log(`MUST RESTORE`);
        return function Persistor() {
            console.log(`MUST PERSIST!!!`);
            // state.persist();
        }
    }, []);
    return (
        <ReducerContext.Provider value={value}>
            {children}
        </ReducerContext.Provider>
    );
};

export default Provider;
