import { useMRT_RowVirtualizer } from "material-react-table";

const DefExport = {
    SOCKETIO_PATH: '/ws',
    LOCAL_STORAGE: {
        TOKEN: 'token'
    },
    // Naming Convention: OBJECT_ACTION
    MESSAGE_TOPICS: {
        HELLO: 'HELLO',
        HELLO_BACK: 'HELLO_BACK',
        CONNECT: 'connect',
        CONNECTION: 'connection',
        DISCONNECT: 'disconnect',
        CASE_CREATE: 'CASE_CREATE',
        CASE_CREATED: 'CASE_CREATED',
        CASE_CREATE_EXTENDED: 'CASE_CREATE_EXTENDED',
        CASE_CREATED_EXTENDED: 'CASE_CREATED_EXTENDED',
        TOPICS_REGISTER: 'TOPICS_REGISTER',
        MM_MSG: 'MM_MSG',
        ERROR_MSG: 'ERROR_MSG',
        JWT_UPDATE: 'JWT_UPDATE',
        UNAUTHORIZED: 'UNAUTHORIZED',
        SFTP_HOSTS_GET: 'SFTP_HOSTS_GET',
        SFTP_HOSTS_SET: 'SFTP_HOSTS_SET',
        CASE_NAMES_GET: 'CASE_NAMES_GET',
        CASE_NAMES_SET: 'CASE_NAMES_SET',
        SFTP_USER_CREATED: 'SFTP_USER_CREATED',
        CLIENTS_GET: 'CLIENTS_GET',
        CLIENTS_SET: 'CLIENTS_SET',
        CLIENT_CREATE: 'CLIENT_CREATE',
        CLIENT_CREATED: 'CLIENT_CREATED',
        USERS_GET: 'USERS_GET',
        USERS_SET: 'USERS_SET',
        USER_CREATE: 'USER_CREATE',
        USER_CREATED: 'USER_CREATED',
        CASES_GET: 'CASES_GET',
        CASES_SET: 'CASES_SET',
        TIME_LOG_CREATE: 'TIME_LOG_CREATE',
        TIME_LOG_CREATED: 'TIME_LOG_CREATED',
        TIME_LOGS_LIST: 'TIME_LOGS_LIST',
        TIME_LOGS_LISTED: 'TIME_LOGS_LISTED',
        TIME_LOG_UPDATE: 'TIME_LOG_UPDATE',
        TIME_LOG_UPDATED: 'TIME_LOG_UPDATED',
        TIME_LOG_DELETE: 'TIME_LOG_DELETE',
        TIME_LOG_DELETED: 'TIME_LOG_DELETED',
        CASE_DELETE: 'CASE_DELETE',
    },
    ACTION_TYPES: {
        SOCKET_SET: 'SOCKET_SET',
        SOCKET_UNSET: 'SOCKET_UNSET',
        JWT_UPDATE: 'JWT_UPDATE',
        UNAUTHORIZED: 'UNAUTHORIZED',
        SFTP_HOSTS_SET: 'SFTP_HOSTS_SET',
        SFTP_HOSTS_LOADING_SET: 'SFTP_HOSTS_LOADING_SET',
        CASE_NAMES_SET: 'CASE_NAMES_SET',
        CASE_NAMES_LOADING_SET: 'CASE_NAMES_LOADING_SET',
        CASE_CREATING_SET: 'CASE_SET_CREATING',
        CUSTOM_CASE_NAME_SET: 'CUSTOM_CASE_NAME_SET',
        SIRCC_CASE_ID_SET: 'SIRCC_CASE_ID_SET',
        SELECTED_CASE_NAME_INDEX_SET: 'SELECTED_CASE_NAME_INDEX_SET',
        SELECTED_SFTP_HOST_SET: 'SELECTED_SFTP_HOST_SET',
        DIALOG_OPEN_SET: 'DIALOG_OPEN_SET',
        SFTP_DETAILS_SET: 'SFTP_DETAILS_SET',
        CLIENTS_SET: 'CLIENTS_SET',
        CLIENTS_LOADING_SET: 'CLIENTS_LOADING_SET',
        CLIENT_CREATING_SET: 'CLIENT_CREATING_SET',
        CLIENT_CREATED: 'CLIENT_CREATED',
        CASE_CREATED: 'CASE_CREATED',
        CASE_CREATED_EXTENDED: 'CASE_CREATED_EXTENDED',
        USERS_SET: 'USERS_SET',
        USERS_LOADING_SET: 'USERS_LOADING_SET',
        USER_CREATING_SET: 'USER_CREATING_SET',
        USER_CREATED: 'USER_CREATED',
        CASES_SET: 'CASES_SET',
        CASES_LOADING_SET: 'CASES_LOADING_SET',
        TIME_LOGS_SET: 'TIME_LOGS_SET',
        TIME_LOGS_LOADING_SET: 'TIME_LOGS_LOADING_SET',
        TIME_LOG_CREATED: 'TIME_LOG_CREATED',
        TIME_LOG_UPDATED: 'TIME_LOG_UPDATED',
        TIME_LOG_DELETED: 'TIME_LOG_DELETED'
    },
    CASE_STATUS: {
        PENDING_CREATION: 'PENDING_CREATION',
        ACTIVE: 'ACTIVE',
        REJECTED: 'REJECTED',
        DISABLED: 'DISABLED',
        CLOSED: 'CLOSED'
    },
    USER_STATUS: {
        PENDING: 'PENDING',
        ACTIVE: 'ACTIVE',
        DELETED: 'DELETED'
    },
    CLIENT_STATUS: {
        PENDING: 'PENDING',
        ACTIVE: 'ACTIVE',
        REJECTED: 'REJECTED',
        DISABLED: 'DISABLED',
        DELETED: 'DELETED'
    },
    ROLES: {
        FINANCIAL: 'FINANCIAL',
        LEGAL: 'LEGAL',
        SIRC: 'SIRC',
        OPERATOR: 'OPERATOR',
        ADMIN: 'ADMIN'
    },
    EMAIL_TEMPLATE: '%SIRCID% has been received by DFI and it is pending evaluation. We tentatively assigned the case name "%CASENAME%" to it. \n\
The forensics officer, responsible for this case is pending assignment. \n\
\n\
======= IMPORTANT ======= \n\
\n\
You can start IMMEDIATELY uploading any relevant case data to the following SFTP server: \n\
\n\
SFTP Host: %SFTPHOSTNAME% \n\
SFTP User: %SFTPUSERNAME% \n\
SFTP Password: %SFTPPASSWORD% \n\
\n\
======= IMPORTANT ======= \n\
\n\
You will be notified at %EMAILADDRESS% if DFI accepts the case. \n\
\n'
    
} as const;

export default DefExport;

export type TRolesType = keyof typeof DefExport.ROLES;
export type TUserStatus = keyof typeof DefExport.USER_STATUS;
export type TCaseStatus = keyof typeof DefExport.CASE_STATUS;

export type TClientStatus = keyof typeof DefExport.CLIENT_STATUS;

export type TToken = {
    id: string;
    username: string;
    role: TRolesType;
    status: TUserStatus;
    temporary: boolean;
}

export enum EnumEvidenceType {
    FILE = "FILE",
    URL = "URL"
};

export enum EnumClientType {
    RETAINER = "RETAINER",
    ADHOC = "ADHOC"
};

export enum EnumObservablesType {
    DOMAIN = "DOMAIN",
    URL = "URL",
    URI = "URI",
    FILEPATH = "FILEPATH",
    IP = "IP",
    SERVICE = "SERVICE"
};

export enum EnumRequestsStatus {
    CREATED = "CREATED",
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    CANCELED = "CANCELED"
};

export enum EnumRequestsType {
    GET_BACKUP = "GET_BACKUP",
    GET_IMAGE = "GET_IMAGE",
    GET_LOGS = "GET_LOGS"
};

export enum EnumTimeLogType {
    REAP = "REAP",
    THOR = "THOR",
    INVESTIGATION = "INVESTIGATION",
    REPORT = "REPORT",
    MEETING = "MEETING",
    OTHER = "OTHER"
};
